/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import {
	watchDetails, addName, deleteList, clearList, updateListUsing, moveNames,
	copyNames,
} from 'services/lists'
import { deleteCustomReward, togglePause, updateCost, updateTitle } from 'services/twitch'

export const useListDetails = ({ listSelected, closeList }) => {
	const [details, setDetails] = useState({}),
		isCustom = listSelected.startsWith('custom-lists'),
		isCustomReward = listSelected.startsWith('custom-reward-queue'),
		isPaused = isCustomReward && details.is_paused

	useEffect(() => {
		setDetails({})
		const stop = watchDetails(listSelected, setDetails, closeList)

		return () => stop()
	}, [listSelected, closeList])

	const [items, setItems] = useState([])

	useEffect(() => {
		let counters = {}

		details.items?.forEach(item => {
			counters[item.nick] = 1 + (counters[item.nick] || 0)
		})

		const items = []
		for (const key in counters) {
			items.push({ nick: key, count: counters[key] })
		}

		setItems(items)
	}, [details.items])

	const handleUseClick = useCallback(() => {
		updateListUsing(listSelected)
	}, [listSelected])

	// ==== CUSTOM LISTS
	const handleAddName = useCallback(() => {
		const nick = prompt("Enter the name to be added in the list:")

		addName(listSelected, nick)
	}, [listSelected])

	const handleDeleteList = useCallback(() => {
		const sure = window.confirm("You are have sure to delete this list? " +
			`There is ${items.length} names here.`)
		sure && deleteList(listSelected)
	}, [listSelected, items.length])

	const handleClearList = useCallback(() => {
		const sure = window.confirm("You are have sure to clear this list? " +
			`There is ${items.length} names here.`)
		sure && clearList(listSelected)
	}, [listSelected, items.length])

	const handleMoveNames = useCallback(() => {
		const list = prompt("Enter the name of the new list:")

		list && moveNames(listSelected, list)
			.then(docSnap => updateListUsing(`custom-lists/${docSnap.id}`))
	}, [listSelected])

	// ==== TWITCH REWARD + CUSTOM REWARD
	const handleCopyNames = useCallback(() => {
		const list = prompt("Enter the name of the new list:")

		list && copyNames(listSelected, list)
			.then(docSnap => updateListUsing(`custom-lists/${docSnap.id}`))
	}, [listSelected])

	// ==== CUSTOM REWARD
	const handleTogglePause = useCallback(() => {
		togglePause(listSelected)
	}, [listSelected])

	const handleEditTitle = useCallback(() => {
		const title = prompt("Enter the title of the reward:")

		updateTitle(listSelected, title)
	}, [listSelected])

	const handleEditCost = useCallback(() => {
		const cost = prompt("Enter the cost of the reward:")

		updateCost(listSelected, cost)
	}, [listSelected])

	const handleDeleteCustomReward = useCallback(() => {
		deleteCustomReward(listSelected)
	}, [listSelected])

	return {
		details, items, isCustom, isCustomReward,
		handleAddName, handleDeleteList, handleClearList, handleUseClick,
		handleMoveNames, handleCopyNames,

		isPaused,
		handleTogglePause, handleEditTitle, handleEditCost, handleDeleteCustomReward,
	}
}