import { useState } from "react"

const useCard = () => {
	const [isActive, setActive] = useState(false)

	const toggleActive = () => setActive(x => !x)

	const cardContextValue = { isActive, setActive, toggleActive }

	return { cardContextValue }
}

export default useCard
