import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { withQuery } from 'utils/withQuery'

const firestore = firebase.firestore(),
	auth = firebase.auth()

const {
	REACT_APP_TWITCH_CLIENT_ID: clientId,
} = process.env

const API_URL = `https://api.twitch.tv/helix/channel_points/custom_rewards`

const getCustomRewardsRedemptions = async rewardId => {
	const broadcaster_id = auth.currentUser.uid.replace("twitch:", "")
	const tokenOAuth = sessionStorage.getItem('twitch-oauth')

	async function get(cursor) {
		return fetch(withQuery(API_URL + '/redemptions', {
			broadcaster_id, // TODO
			status: 'UNFULFILLED', // CANCELED, FULFILLED, UNFULFILLED
			first: 50,
			after: cursor,
			reward_id: rewardId,
		}), {
			method: "GET",
			headers: {
				"Client-Id": clientId,
				"Authorization": "Bearer " + tokenOAuth // TODO
			},
		})
	}

	const list = []
	let cursor

	do {
		const response = await get(cursor)
		const { data, pagination } = await response.json()

		if (!Array.isArray(data) || data === null) return null

		list.push(...data)
		cursor = pagination?.cursor
	} while (cursor)

	return list
}

export const getCustomRewards = async () => {
	const broadcaster_id = auth.currentUser.uid.replace("twitch:", "")
	const tokenOAuth = sessionStorage.getItem('twitch-oauth')

	const response = await fetch(withQuery(API_URL, {
		broadcaster_id,
	}), {
		headers: {
			'client-id': clientId,
			'Authorization': 'Bearer ' + tokenOAuth,
		},
	}).then(r => r.json())

	if (response.error) throw new Error(`${response.error}: ${response.message}`)

	const { data: rewards } = response
	const currLists = {}

	for (const reward of rewards) {
		const redemptions = await getCustomRewardsRedemptions(reward.id)

		if (redemptions === null) continue
		
		currLists[reward.id] = {
			count: redemptions.length,
			title: reward.title,
			items: redemptions.map(x => ({
				nick: x.user_name,
			}))
		}
	}

	return currLists

}

const updateCustomReward = async (listPath, props) => {
	const tokenOAuth = sessionStorage.getItem('twitch-oauth')
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	await firestore.runTransaction(async transaction => {
		const docSnap = await transaction.get(docRef)
		const { broadcaster_id } = docSnap.data()

		const body = typeof props === 'function' ? props(docSnap.data()) : props

		const response = await fetch(withQuery(API_URL, {
			broadcaster_id,
			id: docSnap.id,
		}), {
			method: 'PATCH',
			body: typeof body === 'string' ? body : JSON.stringify(body),
			headers: {
				'client-id': clientId,
				'Authorization': 'Bearer ' + tokenOAuth, // TODO
				'Content-Type': 'application/json',
			},
		}).then(r => r.json())

		if (response.error) throw new Error(`${response.error}: ${response.message}`)

		const { data: [data] } = response

		await transaction.update(docRef, {
			...data,
			color: data.background_color,
			icon: [{
				density: 1,
				url: data.default_image.url_1x,
			}, {
				density: 2,
				url: data.default_image.url_2x,
			}, {
				density: 4,
				url: data.default_image.url_4x,
			}],
			paused: data.is_paused,
		})
	})
}

export const togglePause = async (listPath) =>
	updateCustomReward(listPath, docData => ({ is_paused: !docData.is_paused }))
		.catch(err => window.alert(err))

export const updateTitle = async (listPath, title) =>
	updateCustomReward(listPath, { title })
		.catch(err => window.alert(err))

export const updateCost = async (listPath, cost) =>
	updateCustomReward(listPath, { cost })
		.catch(err => window.alert(err))

export const deleteCustomReward = async (listPath) => {
	const tokenOAuth = sessionStorage.getItem('twitch-oauth')
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	await firestore.runTransaction(async transaction => {
		const docSnap = await transaction.get(docRef)
		const { broadcaster_id } = docSnap.data()

		await fetch(withQuery(API_URL, {
			broadcaster_id,
			id: docSnap.id,
		}), {
			method: 'DELETE',
			headers: {
				'client-id': clientId,
				'Authorization': 'Bearer ' + tokenOAuth,
			},
		})
			.catch(err => window.alert(err))

		await transaction.delete(docRef)
	})
}
