import React, { useCallback } from 'react'

import { Card, Controls } from '../components'
import { FaDharmachakra, FaLink } from 'react-icons/fa'
import { ConfigIcon } from '../elements'
import { copyToClipboard } from 'utils/copyToClipboard'
import firebase from 'firebase/app'
import 'firebase/auth'

const auth = firebase.auth()

export const RouletteCard = () => {
	const copyWidgetUrlHandler = useCallback(() => {
		copyToClipboard(`${process.env.REACT_APP_OVERLAY_URL}/${auth.currentUser.displayName}/roulette`)
	}, [])

	return <Card
		icon={<FaDharmachakra />}
		title="Roulette"
		description="Winner, winner, chicken dinner"
		actions={[{
			key: "copyUrl",
			icon: FaLink,
			props: {
				onClick: copyWidgetUrlHandler,
				title: "Copy URL for this widget"
			}
		}, {
			key: "config",
			icon: ConfigIcon,
		}]}
	>
		<div>
			{/* <select disabled>
				<option>Select the variation</option>
			</select>
			<br />
			<br />
			<select disabled>
				<option>Select the theme</option>
			</select> */}
			{/* <br />
			<label>
				Keep running
					<input type="checkbox" onChange={handleKeepRunning} checked={keepRunningValue} />
			</label> */}
			<Controls />
		</div>
	</Card>
}
