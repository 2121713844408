import { useCallback } from "react"

import { removeOne, deleteUser, addOne } from 'services/lists'

export const useNameItem = ({ listSelected, nick }) => {
	const removeOneHandler = useCallback(() => {
		removeOne(listSelected, nick)
	}, [listSelected, nick])

	const deleteUserHandler = useCallback(() => {
		deleteUser(listSelected, nick)
	}, [listSelected, nick])

	const addOneHandler = useCallback(() => {
		addOne(listSelected, nick)
	}, [listSelected, nick])

	return { removeOneHandler, deleteUserHandler, addOneHandler }
}
