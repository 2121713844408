import styled from "styled-components";

export const Main = styled.main`
	opacity: 0;
	pointer-events: none;

	position: absolute;
	left: 10px; top: 40px;
	right: 10px; bottom: 10px;
	transition: all .4s;
`
