import React from 'react'
import styled from 'styled-components'

import { BackgroundImage, Brands, Glass, LoginCard } from '../components/Auth'

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	position: relative;
	/* background: linear-gradient(127.29deg, #465091 11.76%, #343E79 83.83%); */
`

const Others = styled.div`
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: 3;
`

export const Auth = () => <Container>
	<BackgroundImage />
	<Glass />
	<Others>
		<LoginCard />
		<Brands />
	</Others>
</Container>