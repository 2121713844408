import React from 'react'
import { Container } from './styled/container'
import { RouletteCard, ListViewCard, SFXCard, WinnerCard } from './widgets'

export const Presenter = () => <Container>
	<RouletteCard />
	<ListViewCard />
	<SFXCard />
	<WinnerCard />
</Container>
