import React, { useState } from 'react'

import { ListSelector, ListDetails } from '../components'

export const Lists = () => {
	const [listSelected, setListSelected] = useState()

	const closeList = () => setListSelected()

	return <>
		<ListSelector {...{ setListSelected }} />
		{listSelected && <ListDetails {...{ listSelected, closeList }} />}
	</>
}
