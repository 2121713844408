import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_apiKey,
	authDomain: process.env.REACT_APP_FIREBASE_authDomain,
	databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
	projectId: process.env.REACT_APP_FIREBASE_projectId,
	storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
	messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
	appId: process.env.REACT_APP_FIREBASE_appId,
};

firebase.initializeApp(firebaseConfig);

const firestore	= firebase.firestore(),
	functions	= firebase.functions(),
	auth		= firebase.auth()

if (process.env.NODE_ENV === 'development') {
	firestore.settings({
		host: "localhost:8080",
		ssl: false
	});

	functions.useFunctionsEmulator("http://localhost:5001")

	auth.useEmulator('http://localhost:9099');
}
