import React from 'react'

import { PageContainer } from '../../styled'
import { Accordion } from '../../elements/accordion'
import { NamesContainer } from './styled/NamesContainer'
import { NameItem } from './components/NameItem'
import { Button } from 'components/Button'
import styled from 'styled-components'

const { ListItemHolder, LabelContainer, Label } = Accordion

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 5px;
`

export const Presenter = ({
	closeList, listSelected, details, items, isCustom, isCustomReward,
	handleAddName, handleDeleteList, handleClearList, handleUseClick,
	handleMoveNames, handleCopyNames,

	isPaused,
	handleTogglePause, handleEditTitle, handleEditCost, handleDeleteCustomReward,
}) => <PageContainer>
	<ListItemHolder onClick={closeList}>
		<LabelContainer>
			<Label noCollapse>{details.title} ({details.count})</Label>
		</LabelContainer>
	</ListItemHolder>
	<NamesContainer>
		{items?.sort((a, b) => b.count - a.count).map(item => <NameItem
			key={item.nick}
			{...{ ...item, listSelected, isCustom }}
		/>)}
	</NamesContainer>
	<Actions>
		{isCustom && <Button color="secondary" onClick={handleAddName}>Add name</Button>}
		{isCustom && <Button color="warning" onClick={handleDeleteList}>Delete list</Button>}
		{isCustom && (items.length > 0) && <Button color="warning" onClick={handleClearList}>Clear list</Button>}
		{isCustom && (items.length > 0) && <Button color="warning" onClick={handleMoveNames}>Move all names and use them</Button>}

			{/* max_per_user_per_stream && is_max_per_user_per_stream_enabled ?? */}
			{/* max_per_stream && is_max_per_stream_enabled ?? */}
			{/* background_color */}
			{isCustomReward && <Button color="secondary" onClick={handleTogglePause}>{isPaused ? 'Pause' : 'Unpause'} reward</Button>}
			{isCustomReward && <Button color="secondary" onClick={handleEditTitle}>Update title</Button>}
			{isCustomReward && <Button color="secondary" onClick={handleEditCost}>Update cost</Button>}
			{isCustomReward && <Button color="warning" onClick={handleDeleteCustomReward}>Delete custom reward</Button>}

		{!isCustom && (items.length > 0) && <Button color="warning" onClick={handleCopyNames}>Copy all names and use them</Button>}

		{(items.length > 0) && <Button onClick={handleUseClick}>Use this list</Button>}
	</Actions>
</PageContainer>