import React from 'react'

import { Container } from './elements'
import { HiddenInput } from './components'

export const Presenter = ({ previewUrl }) => <Container>
    {/* <Button>Noti</Button> */}
    <HiddenInput text={previewUrl} />
    {/* <Button>Copy</Button> */}
    {/* <Button>Open</Button> */}
</Container>
