import React, { useCallback } from 'react'
import { FaRocket, FaSync, FaPlus } from 'react-icons/fa'
import { useSyncLists } from 'utils/hooks'
import { createList, createTwitchCustomReward } from 'services/lists'
import { Accordion } from '../elements'
const { Item, List, ListItem } = Accordion

export const TwitchRewardQueue = ({ setListSelected }) => {
	const { update, enabled, twitchLists } = useSyncLists()

	const handleSyncBtn = useCallback(e => {
		e.persist()
		setTimeout(() => e.target?.classList?.add("loading"), 1)

		if (e.target?.classList?.remove)
			e.target.classList.remove("done", "error")

		update()
			.then(() => e.target?.classList?.add("done"))
			.catch(async err => {
				let message
				if (err.status === 401) {
					message = "Please, first add the user LotitchBot as moderator in your stream with the command: /mod LotitchBot"
				} else {
					const error = await err.text()
					message = "Failed to load. " + error
				}
				if (e.target?.setAttribute) e.target.setAttribute("title", message)
				window.alert(message)

				if (e.target?.classList?.add) e.target.classList.add("error")
			})
			.finally(() => {
				if (e.target?.classList?.remove) e.target.classList.remove("loading")
			})
	}, [update])

	const handleListClick = useCallback(key => () => {
		setListSelected(x => x === key ? null : key)
	}, [setListSelected])

	return <Item title="Twitch Reward Queue" buttons={[{
		key: 'refreshLists',
		content: <FaSync />,
		onClick: handleSyncBtn,
		disabled: !enabled,
	}]}>
		<List>
			{twitchLists.map(list => <ListItem
				key={list.key}
				onClick={handleListClick(list.key)}
			>
				<span>{list.title}</span>
				{list.using && <FaRocket />}
			</ListItem>)}
			{twitchLists.length === 0 && <small>No lists found.</small>}
		</List>
	</Item>
}

export const CustomReward = ({ setListSelected }) => {
	const { update, customRewardLists } = useSyncLists()

	const handleCreateBtn = useCallback((e) => {
		e.persist()
		setTimeout(() => e.target?.classList?.add("loading"), 1)

		const title = prompt("Enter the title of the reward:")
		const cost = prompt("Enter the cost of the reward:")

		if (e.target?.classList?.remove) e.target.classList.remove("done", "error")

		createTwitchCustomReward(title, cost)
			.then(() => e.target?.classList?.add("done"))
			.catch(async err => {
				const error = err.text ? await err.text() : `${err}`
				let message = "Failed to load. " + error

				if (e.target?.setAttribute) e.target.setAttribute("title", message)
				window.alert(message)

				if (e.target?.classList?.add) e.target.classList.add("error")
			})
			.finally(() => e.target?.classList?.remove("loading"))
	}, [])


	const handleSyncBtn = useCallback(e => {
		e.persist()
		setTimeout(() => e.target?.classList?.add("loading"), 1)

		if (e.target?.classList?.remove) e.target.classList.remove("done", "error")

		update()// fetch twitch (client-side) then save to firestore
			.then(() => e.target?.classList?.add("done"))
			.catch(async err => {
				const error = err.text ? await err.text() : `${err}`
				let message = "Failed to load. " + error + (error.stack || "")

				if (e.target?.setAttribute) e.target.setAttribute("title", message)
				window.alert(message)

				if (e.target?.classList?.add) e.target.classList.add("error")
			})
			.finally(() => e.target?.classList?.remove("loading"))
	}, [update])

	const handleListClick = useCallback(key => () => {
		setListSelected(x => x === key ? null : key)
	}, [setListSelected])

	return <Item title="Custom Reward" buttons={[{
		key: 'createList',
		content: <FaPlus />,
		onClick: handleCreateBtn,
	}, {
		key: 'refreshLists',
		content: <FaSync />,
		onClick: handleSyncBtn,
	}]}>
		<List>
			{customRewardLists.map(list => <ListItem
				key={list.key}
				onClick={handleListClick(list.key)}
			>
				<span>{list.title}</span>
				{list.using && <FaRocket />}
			</ListItem>)}
			{customRewardLists.length === 0 && <small>No lists found.</small>}
		</List>
	</Item>
}

export const CustomLists = ({ setListSelected }) => {
	const { customLists } = useSyncLists()

	const handleCreateBtn = useCallback(() => {
		const title = prompt("Enter the title of the list:")

		createList(title)
	}, [])

	const handleListClick = useCallback(key => () => {
		setListSelected(x => x === key ? null : key)
	}, [setListSelected])

	return <Item title="Custom Lists" buttons={[{
		key: 'createList',
		content: <FaPlus />,
		onClick: handleCreateBtn,
	}]}>
		<List>
			{customLists.map(list => <ListItem
				key={list.key}
				onClick={handleListClick(list.key)}
			>
				<span>{list.title}</span>
				{list.using && <FaRocket />}
			</ListItem>)}
			{customLists.length === 0 && <small>No lists found.</small>}
		</List>
	</Item>
}