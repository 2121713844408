import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.ul`
	/* height: 100%; */
	flex: 1;
	display: flex;
	flex-direction: column;

	input {
		display: none;
	}
`

const LabelContainer = styled.div`
	height: 32px;
	border-bottom: 1px solid var(--border-color);
	position: relative;
`

const Label = styled.label`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0 6px 0 ${props => props.noCollapse ? "6px" : "1.5em"};
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;
	cursor: ${props => props.disabled ? "inherit" : "pointer"};
	position: relative;

	color: ${props => props.disabled ? "var(--inactive-text-color)" : "inherit"};

	:before {
		/* font-size: .8em; */
		content: '›';/*'▶';*/
		display: ${props => props.noCollapse ? "none" : "initial"};
		position: absolute;
		left: 11px;
		transition: .2s;
	}

	input:checked + div & {
		:before {
			/* content: '▼'; */
			transform: rotate(90deg);
		}
	}
`

const ButtonsContainer = styled.div`
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	gap: 5px;
`

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Button = styled.button`
	background: none;
	border: none;

	display: inherit;
	padding: 0;

	cursor: ${props => props.disabled ? "inherit" : "pointer"};
	color: ${props => props.disabled ? "var(--inactive-text-color)" : "inherit"};

	&.error {
		color: rgba(255,0,0,.5);
	}
	&.done {
		color: rgba(0,255,0,.5);
	}

	&.loading > * {
		animation: ${rotate} 2s linear infinite;
	}

	> * {
		pointer-events: none;
	}
`

const Content = styled.div`
	height: 0;
	overflow: hidden;
	border-bottom: 0 solid var(--border-color);
	transition: .2s;
	--flex: ${({ fill }) => fill ? 1 : "unset"};

	input:checked + div + & {
		padding: 20px 0;
		height: auto;
		border-bottom-width: 1px;
		flex: var(--flex);
	}
`

// const id = Math.random().toString(32)

const ListItemHolder = styled.li`
	flex: ${({ fill }) => fill ? 1 : "unset"};
	display: ${({ fill }) => fill ? "flex" : "unset"};
	flex-direction: ${({ fill }) => fill ? "column" : "unset"};
`

const Item = ({ type, title, key, children, disabled, startsOpen, fill, buttons }) => {
	const id = useRef(Math.random().toString(32).substr(2))

	const [isOpen, setIsOpen] = useState(!!startsOpen)

	return <ListItemHolder fill={fill && isOpen}>
		<input
			{...{ disabled, type }}
			name={id.current}
			id={id.current}
			defaultChecked={startsOpen}
			onChange={e => setIsOpen(e.target.checked)}
		/>
		<LabelContainer>
			<Label htmlFor={id.current} {...{ disabled }}>{title}</Label>
			{buttons && isOpen && <ButtonsContainer>
				{buttons.map(btn => <Button
					key={btn.key}
					onClick={btn.onClick}
					disabled={btn.disabled}
				>
					{btn.content}
				</Button>)}
			</ButtonsContainer>}
		</LabelContainer>
		<Content fill={fill && isOpen}>{children}</Content>
	</ListItemHolder>
}

Item.defaultProps = {
	type: "checkbox"
}

const List = styled.ul`
	small {
		padding: 0 20px;
		font-size: .8em;
		font-style: italic;
	}
`

const ListItem = styled.li`
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;
	height: 28px;
	padding: 0 20px;
	cursor: pointer;

	:hover {
		background-color: var(--inactive-bg-color);
	}

	&.active, &:active {
		color: var(--active-text-color);
		background-color: var(--active-bg-color);
	}

	> span {
		flex: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		line-height: 1.5em;
	}

	> svg {
		color: #5898df;
	}
`

export const Accordion = {
	Container,
	Item,
	List,
	ListItem,

	ListItemHolder,
	LabelContainer,
	Label,
}
