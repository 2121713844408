import styled from "styled-components";

export const Glass = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	--opacity: 0.5;
	--brightness: 60%;
	--firstColor: hsl(232deg 65% var(--brightness) / var(--opacity));
	--secondColor: hsl(231deg 70% var(--brightness) / var(--opacity));
	background: linear-gradient(
		127.29deg,
		var(--firstColor) 11.76%,
		var(--secondColor) 83.83%);
	backdrop-filter: blur(5px);
`