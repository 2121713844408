import styled from "styled-components";

export const Footer = styled.footer`
	text-align: center;
	padding: 15px;
	font-size: 12px;

	b {
		font-weight: bolder;
	}
`
