import React from "react"
import { FaList } from 'react-icons/fa'

import { Container, Spacer } from "./styled"
import { Button } from "./elements"

export const Presenter = () => <Container>
	<Button href="/lists"><FaList /></Button>
	{/* <Button href="/controls"><FaRocket /></Button> */}
	{/* <Button href="/history"><FaHistory /></Button> */}
	<Spacer />
	{/* <Button href="/help"><FaQuestionCircle /></Button> */}
	{/* <Button href="/config"><FaCog /></Button> */}
</Container>
