import React from 'react'
import { Link, useRoute } from 'wouter'
import styled from 'styled-components'

const A = styled.a`
	width: 100%;
	height: 32px;

	transition: .4s;
	overflow: hidden;

	font-size: 20px;
	color: var(--inactive-text-color);
	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	:hover {
		background-color: var(--inactive-bg-color);
	}

	&.active, &:active {
		color: var(--active-text-color);
		background-color: var(--active-bg-color);
	}
`

export const Button = p => <Link href={useRoute(p.href)[0] ? '/' : p.href}>
	<A className={useRoute(p.href)[0] ? 'active' : ''}>{p.children}</A>
</Link>