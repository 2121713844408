import styled from "styled-components"

export const Container = styled.ul`
	grid-area: sidebar;
	background: var(--default-bg-color);
	border-right: 1px solid var(--border-color);

	display: flex;
	flex-direction: column;

	padding: 8px;
`
