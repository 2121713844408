import React from 'react'
import styled from 'styled-components'

import { Navbar, Preview, Sidebar, SidePanel } from 'components'
import { PreviewCommunicationProvider } from 'utils/contexts/PreviewCommunication'
import { WidgetsPanel } from 'components'

export const Container = styled.div`
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-areas:
		"navbar navbar navbar"
		"sidebar sidePanel widgetsPanel"
		"sidebar sidePanel preview";
	grid-template-rows: 50px 1fr 1fr;
	grid-template-columns: 60px auto 1fr;

	body.no-preview & {
		grid-template-rows: 50px 1fr 0px;
	}

	@media (max-width: 300px) {
		grid-template-areas: 
			"..."
	}
`

export const Dashboard = () => {
	return <Container>
		<Navbar />
		<Sidebar />
		<PreviewCommunicationProvider>
			<SidePanel />
			<WidgetsPanel />
			<Preview />
		</PreviewCommunicationProvider>
	</Container>
}
