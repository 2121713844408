import React from "react"

import { Container } from "./styled"
import { Switch, Route } from "wouter"

import { Lists } from "./pages"

export const Presenter = () => <Container>
	<Switch>
		<Route path="/lists" component={Lists} />
	</Switch>
</Container>
