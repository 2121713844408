import styled from "styled-components";

import { Button as DefaultButton } from 'components/Button'

export const Button = styled(DefaultButton)`
	border-radius: 0;
	padding: 2px 4px 1px;

	:hover {
		background-color: #105bbc;
	}
`