import firebase from 'firebase/app'
import 'firebase/functions'

const httpsRequestSeed = firebase.functions().httpsCallable('httpsRequestSeed'),
	httpsRequestVisibility = firebase.functions().httpsCallable('httpsRequestVisibility'),
	httpsShuffleList = firebase.functions().httpsCallable('httpsShuffleList')

export const OVERLAY_COMMANDS = {
	getStatus	: 1,
}

export function newSeed() {
	return httpsRequestSeed()
}

export function changeVisibility(show) {
	return httpsRequestVisibility({ show })
}

export function shuffleList() {
	return httpsShuffleList()
}
