import { useContext, useEffect, useRef } from "react"
import { PreviewCommunication } from "utils/contexts"

/**
 * @typedef Props
 * @property {Function | Function[]} onMessage
 */

/**
 * @param {Function | Function[]} onMessage
 * @returns {import("../contexts/PreviewCommunication").PrevCommValue}
 */
export const usePreviewCommunication = (onMessage) => {
	const propMemo = useRef(onMessage),
		context = useContext(PreviewCommunication),
		{ addMessageHandler, removeMessageHandler } = context

	useEffect(() => {
		const onMessage = propMemo.current
		if (typeof onMessage === 'function')
			addMessageHandler(onMessage)
		else if (onMessage && onMessage.forEach) {
			onMessage.forEach(h => addMessageHandler(h))
		}

		return () => {
			if (typeof onMessage === 'function')
				removeMessageHandler(onMessage)
			else if (onMessage && onMessage.forEach) {
				onMessage.forEach(h => removeMessageHandler(h))
			}
		}
	}, [propMemo, addMessageHandler, removeMessageHandler])

	return context
}
