import styled from "styled-components";
import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useLocation } from "wouter";
import { Button } from "components/Button";
import { FaSignOutAlt } from 'react-icons/fa'
import { ReactComponent as LotitchSVG } from 'assets/imgs/Lotitch.svg'

const Container = styled.nav`
	grid-area: navbar;
	background: var(--default-bg-color);
	border-bottom: 1px solid var(--border-color);
	display: flex;
	align-items: center;
	padding: 0 15px;

	> * {
		flex: 1;
	}
`

const Brand = styled.div``

const Lotitch = styled(LotitchSVG)`
	transform: scale(0.6);
	transform-origin: left;
`
const User = styled.div`
	text-align: center;
	font-family: monospace;
	font-size: 2em;
	text-decoration: underline;
`

const Actions = styled.div`
	text-align: right;
`

export const Navbar = () => {
	const [, setLocation] = useLocation()

	return <Container>
		<Brand>
			<Lotitch />
		</Brand>
		<User>{ firebase.auth().currentUser.displayName }</User>
		<Actions>
			<Button onClick={() => { firebase.auth().signOut(); setLocation("~/auth") }}><FaSignOutAlt />Logout</Button>
		</Actions>
	</Container>
}