import styled from "styled-components";

export const Container = styled.div`
	grid-area: preview;
	background: var(--default-bg-color);

	display: flex;
	flex-direction: column;

	overflow: hidden;
`
