const { default: styled } = require("styled-components");

const Container = styled.div`
	background-color: #ff00001a; //var(--Card_bg_color);
	width: var(--CardWidth, 230px);
	height: 210px;
	border-radius: 10px;
	overflow: hidden;
	transition: all 1.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

	position: relative;

	> svg {
		--size: 65px;
		width: var(--size);
		height: var(--size);

		position: absolute;
		left: 50%; top: 60px;
		transform: translate(-50%, -50%);
		transition: all .4s, left .4s, top .4s;
		transition-timing-function:  linear, ease-out, ease-in;
	}

	&.active {
		transform: rotateY(360deg);

		> svg {
			--size: 20px;
			left: 10px; top: 10px;
			transform: translate(0, 0);
			transition-timing-function:  linear, ease-in, ease-out;
		}

		h3 {
			--size: 17px;
			left: 35px; top: 12px;
			width: fit-content;
			transform: translate(0, 0);
			transition-timing-function:  linear, ease-in, ease-out;
		}

		p {
			opacity: 0;
			pointer-events: none;
		}

		main {
			opacity: 1;
			pointer-events: initial;
		}
	}
`

export default Container
