import React, { useCallback, useRef } from 'react'
import { FaList } from "react-icons/fa"

import { copyToClipboard } from 'utils/copyToClipboard'
import { useSyncLists } from "utils/hooks"
import { Card } from "../components"
import { ConfigIcon } from "../elements"
import { Button as DefaultButton } from 'components/Button'

import firebase from 'firebase/app'
import 'firebase/auth'
import styled from 'styled-components'

const auth = firebase.auth()

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const Button = styled(DefaultButton)`
	display: block;
	margin: 0 auto;
`

export const ListViewCard = () => {
	const { customRewardLists, customLists } = useSyncLists()

	const availableLists = { customRewardLists, customLists }

	const listRef = useRef()

	const copyListUrlHandler = useCallback(() => {
		const value = listRef.current.value
		copyToClipboard(`${process.env.REACT_APP_OVERLAY_URL}/${auth.currentUser.displayName}/list-view/${value}`)
	}, [])

	return <Card
		icon={<FaList />}
		title="List View"
		description="Hi mon, I’m on TV!"
		actions={[{
			key: "config",
			icon: ConfigIcon,
		}]}
	>
		<Container>
			Choose a list
			<select ref={listRef}>
				<option value="using">Currently using</option>
				<optgroup label="Twitch Custom Reward Queue">
					{availableLists.customRewardLists.map(list => <option
						key={list.key}
						value={list.key}
					>
						{list.title}
					</option>)}
					{availableLists.customRewardLists.length === 0 && <option disabled>
						No lists found.
					</option>}
				</optgroup>
				<optgroup label="Custom Lists">
					{availableLists.customLists.map(list => <option
						key={list.key}
						value={list.key}
					>
						{list.title}
					</option>)}
					{availableLists.customLists.length === 0 && <option disabled>
						No lists found.
					</option>}
				</optgroup>
			</select>
			<Button onClick={copyListUrlHandler}>Copy URL for this list</Button>
		</Container>
	</Card>
}