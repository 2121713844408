import React from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { FaCrown } from 'react-icons/fa'
import { usePreviewCommunication, useSyncLists } from 'utils/hooks'
import { Card } from '../components'
import { ConfigIcon } from '../elements'
import { addName, copyRemainingNames, removeOne, createList } from 'services/lists'

export const WinnerCard = () => {
	const { customLists, using } = useSyncLists()

	const [winner, setWinner] = useState()
	const handlePreviewMessage = useCallback(data => {
		setWinner(data.winner)
	}, [])

	usePreviewCommunication(handlePreviewMessage)

	const handleWinnerCmdChange = useCallback(e => {
		const { value } = e.target

		const rx = /(create|add|remove)(?:¬(.*)¬(.*))?/g

		const match = rx.exec(value)

		const cmd = match[1]

		;({
			create: () => {
				let listTitle = window.prompt(`How you want to name the new list?`)

				if (listTitle) {
					createList(listTitle)
						.then(docRef => {
							const listId = docRef.id,
								listPath = `custom-lists/${listId}`
							return addName(listPath, winner)
						})
						.then(() => {
							window.alert(`${winner} was added to ${listTitle}!`)
						})
				}
			},
			add: () => {
				const listTitle = match[2],
					listPath = match[3]
				let confirm = window.confirm(`Are you sure to add ${winner} to ${listTitle}?`)

				if (confirm) {
					addName(listPath, winner)
						.then(() => {
							window.alert(`${winner} was added to ${listTitle}!`)
						})
				}
			},
			remove: () => {
				if (using.key.startsWith('custom-lists')) {
					const sure = window.confirm(`Are you sure to remove the fist occurrence of ${winner} in ${using.title}?`)

					if (sure) removeOne(using.key, winner)
				} else {
					let listTitle = window.prompt(`The current list are controlled by Twitch Custom Reward. So to remove this name, I have to create a new list with the remaining names. If you are sure to do it, how you want to name the new list?`)

					if (listTitle) {
						copyRemainingNames(winner, listTitle)
							.then(() => {
								window.alert(`The list was created and already set to be used!`)
							})
					}
				}
			}
		})[cmd]()
	}, [winner, using])

	return <Card
		icon={<FaCrown />}
		title="Winner"
		description="I won, I won!"
		actions={[{
			key: "config",
			icon: ConfigIcon,
		}]}
	>
		<div>
			{!winner && "No winner yet."}
			{winner && <>Winner: <b>{winner}</b></>}
			{winner && <select onChange={handleWinnerCmdChange} defaultValue="winner">
				<option disabled value="winner">Actions...</option>
				<optgroup label="Add winner to...">
					<option value="create">New list</option>
					<option disabled>──────────</option>
					{customLists.map(list => <option
						key={list.key}
						value={`add¬${list.title}¬${list.key}`}
					>
						{list.title}
					</option>)}
				</optgroup>
				<option value="remove">Remove winner from this list</option>
			</select>}
		</div>
	</Card>
}
