import React from 'react'
import { FaMinus, FaTrash, FaPlus } from 'react-icons/fa'

import { Accordion } from '../../../../elements/accordion'
import { Count } from './styled/Count'
import { ButtonsContainer } from './styled/ButtonsContainer'
import { Button } from './styled/Button'
import styled from 'styled-components'

const { ListItem: DefaultListItem } = Accordion

const ListItem = styled(DefaultListItem)`
	cursor: initial;
`

export const Presenter = ({
	nick, count, removeOneHandler, deleteUserHandler, addOneHandler, isCustom,
}) => <ListItem>
	<span>{nick}</span>
	<Count>{count}</Count>
	{isCustom && <ButtonsContainer>
		<Button color="secondary" onClick={removeOneHandler}><FaMinus /></Button>
		<Button color="secondary" onClick={deleteUserHandler}><FaTrash /></Button>
		<Button color="secondary" onClick={addOneHandler}><FaPlus /></Button>
	</ButtonsContainer>}
</ListItem>
