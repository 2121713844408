import styled from "styled-components";

export const Button = styled.button`
	font-size: 20px;
	padding: ${({ small }) => small ? "3px 4px" : "4px 16px"};
	border: 0;
	border-radius: 2px;
	background-color: ${({ color }) => ({
		"primary"	: "#2F80ED",
		"secondary"	: "#333333",
		"warning"	: "#ED732F",
	}[color || "primary"]) };
	color: white;
	cursor: pointer;

	:disabled {
		opacity: .6;
	}
`
