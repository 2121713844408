import React from 'react'

import { Container } from './elements';
import { AddressBar, OverlayView } from './components';
import { PreviewContext } from './context';

export const Presenter = ({ contextValue }) => <Container>
	<PreviewContext.Provider value={contextValue}>
		<AddressBar />
		<OverlayView allow="autoplay" />
	</PreviewContext.Provider>
</Container>
