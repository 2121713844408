import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'

import { throwIfNotOk } from "utils"
import { getCustomRewards } from './twitch'

const httpsUpdateListUsing = firebase.functions().httpsCallable('httpsUpdateListUsing')
const httpsCreateCustomReward = firebase.functions().httpsCallable('httpsCreateCustomReward')

const FieldValue = firebase.firestore.FieldValue,
	firestore = firebase.firestore(),
	auth = firebase.auth()

const {
	REACT_APP_FUNCTIONS_URL: API_URL,
	// REACT_APP_TWITCH_CLIENT_ID: clientId,
} = process.env


export const updateTwitchListsWithAPI = async () => {

	const currLists = await getCustomRewards()

	const listsCollection = firestore.collection(`channels/${auth.currentUser.displayName}/custom-reward-queue`)

	const lastSavedLists = await listsCollection.get()

	/** @type {import('firebase').firestore.DocumentReference<firebase.firestore.DocumentData>[]} */
	const listsToRemove = []
	/** @type {import('firebase').firestore.DocumentReference<firebase.firestore.DocumentData>[]} */
	const listsToUpdate = []

	lastSavedLists.docs.forEach(doc => {
		if (currLists[doc.id]) {
			listsToUpdate.push(doc.ref)
		} else {
			listsToRemove.push(doc.ref)
		}
	})

	for (const docToRemove of listsToRemove) {
		await docToRemove.delete()
	}

	for (const docToUpdate of listsToUpdate) {
		await docToUpdate.set(currLists[docToUpdate.id], { merge: true })
		delete currLists[docToUpdate.id]
	}

	for (const listToAdd in currLists) {
		await listsCollection
			.doc(listToAdd)
			.set(currLists[listToAdd])
	}
}

export const updateTwitchLists = async () => {
	const a = fetch(API_URL + 'httpsBrowser/update-reward-queue?user=' + auth.currentUser.displayName)
		.then(throwIfNotOk)
	const b = updateTwitchListsWithAPI()

	await Promise.all([a, b])
}

export const createTwitchCustomReward = async (title, cost) => {
	const { data: result } = await httpsCreateCustomReward({ title, cost })

	if (result.error) {
		console.error(result)
		throw result.message
	}
}

export const getDetails = async listPath => {
	const docData = await firestore
		.doc(`channels/${auth.currentUser.displayName}/${listPath}`)
		.get()
		.then(docSnap => {
			if (!docSnap.exists) throw new Error('List doesn\'t exists.')

			return docSnap.data()
		})

	return docData
}

export const watchDetails = (listPath, callback, onError) => {
	const stop = firestore
		.doc(`channels/${auth.currentUser.displayName}/${listPath}`)
		.onSnapshot(docSnap => {
			if (!docSnap.exists) onError(new Error('List doesn\'t exists.'))

			callback(docSnap.data())
		})

	return stop
}

export const updateListUsing = listPath => {
	return httpsUpdateListUsing({ list: listPath })
}

export const createList = title => {
	return firestore
		.collection('channels')
		.doc(auth.currentUser.displayName)
		.collection('custom-lists')
		.add({ title })
}

export const addName = (listPath, nick) => {
	if (!nick) return

	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	return firestore.runTransaction(transaction => {
		return transaction.get(docRef).then(docSnap => {
			const items = docSnap.get('items') || []
			items.push({ nick })

			transaction.update(docRef, {
				items: items,
				count: FieldValue.increment(1),
			})
		})
	})
}

export const deleteList = listPath => {
	return firestore
		.doc(`channels/${auth.currentUser.displayName}/${listPath}`)
		.delete()
}

export const clearList = listPath => {
	return firestore
		.doc(`channels/${auth.currentUser.displayName}/${listPath}`)
		.update({ items: [], count: 0 })
}

function removeElement(array, elem) {
	var index = array.indexOf(elem);
	if (index > -1) {
		array.splice(index, 1);
	}
}

export const copyRemainingNames = async (winner, newListName) => {

	const list = await firestore
		.collection('overlays')
		.doc(auth.currentUser.displayName)
		.get()
		.then(docSnap => {
			if (docSnap.exists) {
				return docSnap.get("list")
			}
		})

	if (list) {
		removeElement(list, winner)

		const docSnap = await firestore
			.collection(`channels/${auth.currentUser.displayName}/custom-lists`)
			.add({
				title: newListName,
				items: list.map(x => ({ nick: x })),
				count: list.length,
			})

		updateListUsing(`custom-lists/${docSnap.id}`)
	}

}

export const removeOne = (listPath, name) => {
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	return firestore.runTransaction(transaction => {
		return transaction.get(docRef).then(docSnap => {
			const items = docSnap.get('items') || [],
				idx = items.findIndex(x => x.nick === name)

			items.splice(idx, 1)

			transaction.update(docRef, {
				items: items,
				count: FieldValue.increment(-1),
			})
		})
	})
}

export const deleteUser = (listPath, name) => {
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	return firestore.runTransaction(transaction => {
		return transaction.get(docRef).then(docSnap => {
			const items = docSnap.get('items').filter(x => x.nick !== name) || []

			transaction.update(docRef, {
				items,
				count: items.length,
			})
		})
	})
}

export const addOne = (listPath, name) => {
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listPath}`)

	return firestore.runTransaction(transaction => {
		return transaction.get(docRef).then(docSnap => {
			const items = docSnap.get('items') || []

			items.push({ nick: name })

			transaction.update(docRef, {
				items: items,
				count: FieldValue.increment(1),
			})
		})
	})
}

export const moveNames = (listSelectedPath, newListName) => {
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listSelectedPath}`),
		newDocRef = firestore.collection(`channels/${auth.currentUser.displayName}/custom-lists`).doc()

	return firestore.runTransaction(transaction => {
		return transaction
			.get(docRef)
			.then(docSnap => docSnap.get('items'))
			.then(items => transaction
				.set(newDocRef, {
					title: newListName, items, count: items.length
				}))
			.then(() => transaction.update(docRef, { items: [], count: 0 }))
			.then(() => newDocRef)
	})
}

export const copyNames = (listSelectedPath, newListName) => {
	const docRef = firestore.doc(`channels/${auth.currentUser.displayName}/${listSelectedPath}`),
		newDocRef = firestore.collection(`channels/${auth.currentUser.displayName}/custom-lists`).doc()

	return docRef
		.get()
		.then(docSnap => docSnap.get('items'))
		.then(items => newDocRef.set({
			title: newListName, items, count: items.length
		}))
		.then(() => newDocRef)
}
