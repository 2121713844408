import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firestore = firebase.firestore(),
	auth = firebase.auth()

export const updateRouletteSFX = name => {
	return firestore
		.collection('channels')
		.doc(auth.currentUser.displayName)
		.collection('sfx')
		.doc('roulette')
		.set({ name })
}

export const muteLiveSFX = mute => {
	return firestore
		.collection('channels')
		.doc(auth.currentUser.displayName)
		.collection('sfx')
		.doc('roulette')
		.set({ mute })
}
