import { useEffect, useState } from "react"
import firebase from 'firebase/app'
import 'firebase/auth'

const auth = firebase.auth()

export const useLogic = () => {
	const [previewUrl, setPreviewUrl] = useState()

	useEffect(() => {
		setPreviewUrl(`${process.env.REACT_APP_OVERLAY_URL}/${auth.currentUser.displayName}/roulette`)
	}, [])

	const contextValue = { previewUrl }
	return { contextValue }
}
