import React, { useEffect, useCallback, useState } from 'react'

import { OVERLAY_COMMANDS } from 'services'
import { usePreviewCommunication } from 'utils/hooks'
// import { PageContainer } from '../styled'
import { Button } from 'components/Button'
// import { Accordion, Chances } from '../elements'
import styled from 'styled-components'
import { changeVisibility, newSeed, shuffleList } from 'services/overlay'

// const { Container, Item } = Accordion

const ButtonsContainer = styled.div`
    /* padding: 20px 0; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Controls = () => {
    const [widgetIsShowing, setWidgetIsShowing] = useState(false)

    const handleWidgetMessage = useCallback(message => {
        console.log('[dashboard] received:', message)
    }, [])

    const comm = usePreviewCommunication(handleWidgetMessage),
        { ready, sendMessage } = comm

    useEffect(() => {
        const id = setInterval(() => {
            setDisableShow(true)
            if (ready) {
                sendMessage(OVERLAY_COMMANDS.getStatus)
                    .then(status => setWidgetIsShowing(status.visibility))
                    .finally(() => setDisableShow(false))
                clearInterval(id)
            }
        })
        
        return () => clearInterval(id)
    }, [ready, sendMessage])
    
    const [disableShow, setDisableShow] = useState(false)
    const handleShow = useCallback(() => {
        setDisableShow(true)
        changeVisibility(true)
            .then(() => setWidgetIsShowing(true))
            .finally(() => setDisableShow(false))
    }, [])

    const [disableHide, setDisableHide] = useState(false)
    const handleHide = useCallback(() => {
        setDisableHide(true)
        setDisableShuffle(true)
        setDisableStart(true)
        changeVisibility(false)
            .then(() => setWidgetIsShowing(false))
            .finally(() => setDisableHide(false) || setDisableShuffle(false) || setDisableStart(false))
    }, [])

    const [disableShuffle, setDisableShuffle] = useState(false)
    const handleShuffle = useCallback(() => {
        setDisableStart(true)
        setDisableShuffle(true)
        shuffleList()
            .finally(() => setDisableStart(false) || setDisableShuffle(false))
    }, [])

    const [disableStart, setDisableStart] = useState(false)
    const handleStart = useCallback(() => {
        setDisableStart(true)
        setDisableShuffle(true)
        newSeed()
            .finally(() => setDisableStart(false) || setDisableShuffle(false))
    }, [])

    const buttons = []
    if (!widgetIsShowing) {
        buttons.push(() => <Button
            color="secondary"
            onClick={handleShow}
            disabled={!ready || disableShow}
        >Show</Button>)
    } else {
        buttons.push(...[
            () => <Button
                color="secondary"
                onClick={handleHide}
                disabled={!ready || disableHide}
            >Hide</Button>,
            () => <Button
                color="secondary"
                onClick={handleShuffle}
                disabled={!ready || disableShuffle}
            >Shuffle</Button>,
            () => <Button
                onClick={handleStart}
                disabled={!ready || disableStart}
            >Start</Button>
        ])
    }

    return <>
        <ButtonsContainer>
            {buttons.map((C, i) => <C key={i} />)}
        </ButtonsContainer>
    </>
}
