import { useCallback, useEffect, useState } from "react"
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { updateTwitchListsWithAPI } from "services/lists"

const firestore = firebase.firestore(),
	auth = firebase.auth()

function snapshotListener(collection, updater, setUsing) {
	return () => {
		const unsubscribe = firestore
			.collection('channels')
			.doc(auth.currentUser.displayName)
			.collection(collection)
			.onSnapshot(async querySnap => {
				const newLists = []

				let using

				querySnap.docs.forEach(docSnap => {
					newLists.push({
						key: [docSnap.ref.parent.id, docSnap.id].join("/"),
						title: docSnap.get('title'),
						using: !!docSnap.get('using'),
					})

					if (docSnap.get('using')) {
						using = {
							key: [docSnap.ref.parent.id, docSnap.id].join("/"),
							title: docSnap.get('title'),
							items: docSnap.get('items').map(x => x.nick),
						}
					}
				})

				setUsing(lastUsing => lastUsing?.key.startsWith(collection) ?
					using : (lastUsing || using))

				updater(newLists)
			})
		return unsubscribe
	}
}

export const useSyncLists = () => {
	const [enabled, setEnabled] = useState(true)

	const update = useCallback(() => {
		setEnabled(false)
		return updateTwitchListsWithAPI()
			.finally(() => setEnabled(true))
	}, [])

	const [twitchLists, setTwitchLists] = useState([]),
		[customLists, setCustomLists] = useState([]),
		[customRewardLists, setCustomRewardLists] = useState([]),
		[using, setUsing] = useState()

	useEffect(snapshotListener('reward-queue', setTwitchLists, setUsing), [])
	useEffect(snapshotListener('custom-lists', setCustomLists, setUsing), [])
	useEffect(snapshotListener('custom-reward-queue', setCustomRewardLists, setUsing), [])

	return { update, enabled, twitchLists, customLists, customRewardLists, using }
}
