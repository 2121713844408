import React from 'react'
import styled from "styled-components"

const Container = styled.div`
	position: absolute;
	right: 0; top: 0;
	padding: 10px;
	display: flex;
	flex-direction: row;

	> svg {
		cursor: pointer;
		--size: 20px;
		width: var(--size);
		height: var(--size);

		:not(:last-child) {
			margin-right: 10px;
		}
	}
`

export const Actions = ({ actions }) => <Container>
	{actions.map(action => <action.icon key={action.key} {...action.props} />)}
</Container>
