import styled from "styled-components";

export const Description = styled.p`
	position: absolute;
	font-size: 16px;
	width: max-content;

	left: 50%; top: 165px;
	transform: translate(-50%, -50%);
	transition: all .4s;
	opacity: 1;
`
