import React, { createContext, useCallback, useEffect, useState } from 'react'

function getSecureOrigin() {
	const secureSrc = document.querySelector('iframe[name=preview]').src
	const secureOrigin = new URL(secureSrc).origin
	return secureOrigin
}

/**
 * @typedef PrevCommValue
 * @property {Boolean} ready
 * @property {(message: String) => void} sendMessage
 * @property {(handler: Function) => void} addMessageHandler
 * @property {(handler: Function) => void} removeMessageHandler
 */

 /** @type {React.Context<PrevCommValue>} */
export const PreviewCommunication = createContext({})

export const PreviewCommunicationProvider = ({ children }) => {
	const [ready, setReady] = useState(false)

	const [handlers, setHandlers] = useState([])
	const addMessageHandler = useCallback(handler => {
		setHandlers(x => [...x, handler])
	}, [])
	const removeMessageHandler = useCallback(handler => {
		setHandlers(x => x.filter(y => y !== handler))
	}, [])

	const sendMessage = useCallback(async message => {
		if (!ready) throw new Error("The frame didn't respond yet. Please, check the `ready` state to prevent losing messages.")

		const confirmationCode = Math.random().toString(10).substring(2)

		return new Promise((res, rej) => {

			function finalize(value = null) {
				window.removeEventListener('message', handleMessage)
				if (value !== null) res(value)
				else rej("The message was not responded in 30s. Confirmation code: "+confirmationCode)
			}

			let id = setTimeout(finalize, 30000)
			function handleMessage(e) {
				if (e.data?.confirmationCode === confirmationCode) {
					clearTimeout(id)
					finalize(e.data?.data)
				}
			}

			window.addEventListener('message', handleMessage)
			
			window.frames.preview.postMessage({
				confirmationCode, message,
			}, getSecureOrigin())
		})
	}, [ready])

	useEffect(() => {
		function handleMessage(e) {
			if (e.origin === window.origin) return

			if (e.origin !== getSecureOrigin()) return // something from an unknown origin
			
			setReady(true)
			handlers.forEach(c => c.call(null, e.data))
		}

		window.addEventListener('message', handleMessage)

		return () => window.removeEventListener('message', handleMessage)
	}, [handlers])

	/** @type {PrevCommValue} */
	const value = { ready, sendMessage, addMessageHandler, removeMessageHandler }

	return <PreviewCommunication.Provider {...{ value }}>
		{children}
	</PreviewCommunication.Provider>
}
