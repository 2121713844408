/* eslint-disable no-unused-expressions */
import React from 'react'

import { PageContainer } from '../styled'
import { Accordion } from '../elements'
import { CustomReward, CustomLists } from './lists'
const { Container } = Accordion

export const ListSelector = ({ setListSelected }) => {
	return <PageContainer>
		<Container>
			<CustomReward {...{ setListSelected }} />
			<CustomLists {...{ setListSelected }} />
		</Container>
	</PageContainer>
}
