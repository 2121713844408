import styled from "styled-components";

import city from "assets/imgs/city.png"

export const BackgroundImage = styled.img.attrs(props => ({
  src: city,
}))`
	/* mix-blend-mode: screen; */
	width: 100%;
	height: 100%;
	object-fit: cover;

	position: absolute;
	left: 0;
	top: 0;
`