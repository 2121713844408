import firebase from 'firebase/app'
import 'firebase/functions'
import { sameOrigin } from 'utils'

const getAuthToken = firebase.functions().httpsCallable('httpsTwitchAuth')

const {
	REACT_APP_TWITCH_CLIENT_ID: clientId,
	REACT_APP_TWITCH_REDIRECT_URI: redirectUri
} = process.env

export const AuthenticateWithTwitch = async () => {
	const codeUri = `https://id.twitch.tv/oauth2/authorize?` +
					`client_id=${clientId}&` +
					`redirect_uri=${redirectUri}&` +
					`response_type=code&` +
					`scope=openid user_read channel:manage:redemptions channel:read:redemptions&`

	const code = await getCode(codeUri)

	const authToken = (await getAuthToken({ code })).data

	const [firebaseToken, twitchToken] = authToken.split(":")

	sessionStorage.setItem('twitch-oauth', twitchToken)

	return firebase.auth().signInWithCustomToken(firebaseToken);
}

/** @param {String} uri */
const getCode = uri => {
	return new Promise((res, rej) => {
		const authWindow = window.open(uri, "_blank",
			"toolbar=yes,scrollbars=yes,resizable=yes,width=500,height=500")

		let i
		const o = setTimeout(() => {
			clearInterval(i)
			rej("Timeout")
		}, 60 * 1000)

		i = setInterval(() => {
			const comeBackFromTwitch = sameOrigin(authWindow) &&
				authWindow?.location?.href
					.includes(process.env.REACT_APP_TWITCH_REDIRECT_URI)

			if (comeBackFromTwitch) {
				const url = new URL(authWindow?.location?.href)
				const code = url.searchParams.get('code')

				/* eslint-disable no-unused-expressions */
				authWindow?.close()
				clearInterval(i)
				clearTimeout(o)

				if (code) {
					res(code)
				} else {
					rej(`Error while getting code from ${url.href}`)
				}
			}
		}, 500)
	})
}