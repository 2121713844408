import React from 'react'
import { useRouter, useLocation, Router } from 'wouter'

export const WouterScope = ({ base, children, ...props}) => {
	const router = useRouter()
	const [parentLocation] = useLocation()

	const nestedBase = `${router.base}${base}`

	// don't render anything outside of the scope
	if (!parentLocation.startsWith(nestedBase)) return null

	// we need key to make sure the router will remount if the base changes
	return (
		<Router base={nestedBase} key={nestedBase} {...props}>
			{children}
		</Router>
	)
}
