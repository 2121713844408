import React from 'react';
import { Route, Redirect } from 'wouter'
import 'reset-css';

import 'services/firebase-init'

import { Dashboard, Auth } from './pages'
import styled from 'styled-components';
import { AuthScope, WouterScope } from './utils';

const Container = styled.div`
	font-family: sans-serif;
	--variable-mask: 255;
	background-color: black;

	&.light {
		--variable-mask: 0;
		background-color: white;
	}
	
	--default-bg-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.084);
	--inactive-bg-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.119);
	--active-bg-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.173);
	--border-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.14);
	--inactive-text-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.31);
	--active-text-color: rgba(
		var(--variable-mask),
		var(--variable-mask),
		var(--variable-mask),
		.899);

	color: var(--active-text-color);

	--Card_bg_color: var(--active-bg-color);

	select {
		font-size: 1em;
		max-width: 100%;
	}
`

export const App = () => {
	return <Container>
		<AuthScope
			base="/dashboard"
			type="private"
			redirectPath="~/auth"
		>
			<Dashboard />
		</AuthScope>
		<AuthScope
			base="/auth"
			type="publicOnly"
			redirectPath="~/dashboard"
		>
			<Auth />
		</AuthScope>
		<WouterScope base="/code" />
		<Route><Redirect to="/dashboard" /></Route>
	</Container>
}
