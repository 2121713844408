import React from 'react'
import { FaCog, FaTimes } from 'react-icons/fa'

import { useCardContext } from '../components/Card/context'

export const ConfigIcon = () => {
	const { isActive, toggleActive } = useCardContext()

	const Component = isActive ? FaTimes : FaCog

	return <Component onClick={toggleActive} />
}
