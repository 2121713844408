import React, { useCallback, useEffect, useState } from 'react'
import { FaMusic } from 'react-icons/fa'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { muteLiveSFX, updateRouletteSFX } from 'services/sfx'

import { Card } from '../components'
import { ConfigIcon } from '../elements/ConfigIcon'
import { Button } from 'components'
import { usePreviewCommunication } from 'utils/hooks'

const auth = firebase.auth()

export const SFXCard = () => {
	const SFXChangeHandler = useCallback(e => {
		e.persist()
		const value = e.target.value
		updateRouletteSFX(value)
	}, [])

	const [SFXValue, setSFXValue] = useState()

	useEffect(() => {
		const stop = firebase.firestore()
			.collection('channels')
			.doc(auth.currentUser.displayName)
			.collection('sfx')
			.doc('roulette')
			.onSnapshot(docSnap => {
				setSFXValue(docSnap.get('name'))
			})

		return stop
	}, [])

	const [liveMuted, setLiveMuted] = useState(false)

	useEffect(() => {
		const stop = firebase.firestore()
			.collection('channels')
			.doc(auth.currentUser.displayName)
			.collection('sfx')
			.doc('roulette')
			.onSnapshot(docSnap => {
				setLiveMuted(!!docSnap.get('mute'))
			})

		return stop
	})

	const muteLiveHandler = useCallback(() => {
		muteLiveSFX(!liveMuted)
	}, [liveMuted])

	const [previewMuted, setPreviewMuted] = useState(true)

	const previewMessageHandler = useCallback(message => {
		if (message.data && typeof message.data.muteSFX !== 'undefined')
			setPreviewMuted(message.data.muteSFX)
	}, [])

	const { sendMessage } = usePreviewCommunication(previewMessageHandler)

	const mutePreviewHandler = useCallback(() => {
		sendMessage("muteSFX")
	}, [sendMessage])

	return <Card
		icon={<FaMusic />}
		title="SFX"
		description="That's sound good"
		actions={[{
			key: "config",
			icon: ConfigIcon,
		}]}
	>
		<div>
			Choose a sound effect
			<br />
			<select id="sfxList" onChange={SFXChangeHandler} value={SFXValue}>
				<option>SFX 1</option>
				<option>SFX 2</option>
				<option>SFX 3</option>
				<option>SFX 4</option>
				<option>SFX 5</option>
				<option>SFX 6</option>
				<optgroup label="Professional">
					<option>Crystal</option>
					<option>Marimba</option>
					<option>Minimalist 1</option>
					<option>Minimalist 2</option>
					<option>Minimalist 3</option>
					<option>Minimalist 4</option>
					<option>Pitched</option>
				</optgroup>
			</select>
		</div>
		<br />
		<Button onClick={mutePreviewHandler}>
			{previewMuted ? "Unmute" : "Mute"} Preview
		</Button>
		<Button onClick={muteLiveHandler}>{liveMuted ? "Unmute" : "Mute"} Live</Button>
	</Card>
}
