import React from 'react'
import { useLocation } from 'wouter'

import { useTwitchLogin } from './useTwitchLogin'
import { WouterScope } from './WouterScope'

function PublicComponent({ children }) {
	return children
}

function PublicOnlyComponent({ redirectPath, children }) {
	const { user, pending } = useTwitchLogin(true),
		[, setLocation] = useLocation()

	return !pending
		? user
			? (setLocation(redirectPath), null)
			: children
		: null
}

function PrivateComponent({ redirectPath, children }) {
	const { user, pending } = useTwitchLogin(true),
		[, setLocation] = useLocation()
	
	return !pending
		? !user
			? (setLocation(redirectPath), null)
			: children
		: null
}

function AuthScope({ type, children, redirectPath, ...rest }) {
	const { pending } = useTwitchLogin(true)

	const Component = {
		default: PublicComponent,
		publicOnly: PublicOnlyComponent,
		private: PrivateComponent,
	}[type || "default"]

	return !pending ? <WouterScope {...rest}>
		<Component {...{ redirectPath }}>
			{children || null}
		</Component>
	</WouterScope> : null
}

export { AuthScope }