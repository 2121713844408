import React from 'react'
import Container from './styled/Container'
import { Provider } from './context'
import { Title } from './styled/Title'
import { Description } from './styled/Description'
import { Actions } from './elements/Actions'
import { Main } from './styled/Main'

const Presenter = ({
	icon, title, description, actions,
	cardContextValue, children,
}) => <Provider value={cardContextValue}>
	<Container className={cardContextValue.isActive ? "active" : ""}>
		{ icon }
		<Title>{ title }</Title>
		<Actions {...{ actions }} />
		<Description>{ description }</Description>
		<Main>{ children }</Main>
	</Container>
</Provider>

export default Presenter
