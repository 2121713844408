import styled from "styled-components";

export const Container = styled.div`
	grid-area: widgetsPanel;
	background: var(--default-bg-color);

	display: grid;
	grid-auto-rows: min-content;
	--CardWidth: 320px;
	grid-template-columns: repeat(auto-fit, var(--CardWidth));
	justify-content: center;
	justify-items: center;
	justify-self: center;
	grid-gap: 8px;
	padding: 8px;
	overflow: auto;
	max-width: calc(100% - 8px * 2);
	width: 100%;
	font-size: 1.3em;

	@media (min-width: 800px) {
		> :nth-child(3n+1) {
			margin-left: auto;
		}
		> :nth-child(3n) {
			margin-right: auto;
		}
	}
	@media (max-width: 800px) and (min-width: 550px) {
		> :nth-child(2n+1) {
			margin-left: auto;
		}
		> :nth-child(2n) {
			margin-right: auto;
		}
	}
	@media (max-width: 550px) {
		width: calc(100% - 16px);
		> * {
			margin-left: auto;
			margin-right: auto;
		}
	}
`
