import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as TwitchSVG } from 'assets/imgs/Twitch.svg'
import { useTwitchLogin } from 'utils'
import { useLocation } from 'wouter'

const Container = styled.div`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: normal;

	position: absolute;
	left: calc(50% - 450px / 2);
	top: calc(55% - 200px);

	background: #FFFFFF;
	box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	
	width: 450px;
	height: 400px;
	box-sizing: border-box;

	color: #151515;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 30px;
`

const H1 = styled.h1`
	font-weight: bold;
	font-size: 30px;
	line-height: 36px;
	flex-grow: 1;
`

const H6 = styled.h6`
	font-size: 18px;
	line-height: 28px;
	flex-grow: 1;
`

const Connect = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 4;
	justify-content: space-around;
	margin: 30px 0px 90px;
`

const Span = styled.span`
	font-weight: 300;
`

const Button = styled.button`
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;

	width: 190px;
	height: 40px;

	color: #FFFFFF;
	background: #9146FF;
	border: none;
	border-radius: 70px;

	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0 40px;

	cursor: pointer;

	transition: all .3s;
	:hover {
		box-shadow: 3px 3px 10px rgb(0 0 0 / .2);
	}

	:disabled {
		position: relative;
		overflow: hidden;
		background: hsl(264 100% 20% / 1);

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 200%;
			height: 100%;
			transform: translateX(-100%);
			background-image: linear-gradient(
				135deg,
				rgb(255 255 255 / 0) 20%,
				rgb(255 255 255 / 0.2) 40%,
				rgb(255 255 255 / 0.5) 50%,
				rgb(255 255 255 / 0.2) 60%,
				rgb(255 255 255 / 0) 80%
			);
			animation: shimmer 3s infinite;
			content: '';
		}

		@keyframes shimmer {
			100% {
				transform: translateX(100%);
			}
		}
	}
`

export const LoginCard = () => {
	const { login } = useTwitchLogin(),
		[loading, setLoading] = useState(false),
		[, setLocation] = useLocation()
	
	const handleClick = useCallback(async () => {
		setLoading(true)
		await login()
		setLoading(false)
		setLocation("~/dashboard")
	}, [login, setLocation])

	return <Container>
		<H1>Welcome back!</H1>
		<H6>We’re so excited to see you again!</H6>
		<Connect>
			<Span>Connect with:</Span>
			<Button disabled={loading} onClick={handleClick}>
				<TwitchSVG /> TWITCH
			</Button>
		</Connect>
		<Span>Don’t have an account yet? <a href="#!">Sign Up</a></Span>
	</Container>
}
