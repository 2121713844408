import styled from "styled-components";

export const Title = styled.h3`
	position: absolute;
	--size: 19px;
	font-size: var(--size);
	width: max-content;

	left: 50%; top: 130px;
	transform: translate(-50%, -50%);
	transition: all .4s, left .4s, top .4s;
	transition-timing-function:  linear, ease-out, ease-in;
`
