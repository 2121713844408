import styled from "styled-components";

export const PageContainer = styled.div`
	width: 250px;
	height: 100%;
	border-right: 1px solid var(--border-color);

	display: flex;
	flex-direction: column;
	overflow: hidden;
`
