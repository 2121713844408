import { useState, useEffect, useRef } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

import { AuthenticateWithTwitch } from 'services/login'

const login = () => AuthenticateWithTwitch()
const logout = () => firebase.auth().signOut()

/**
 * @param {Boolean} autoStop	Stop listening to auth state
 * 								after the first state
 */
export const useTwitchLogin = autoStop => {
	const [user, setUser] = useState(firebase.auth().currentUser),
		[pending, setPending] = useState(true),
		unsubscribe = useRef(() => { }),
		reallyAutoStop = useRef(autoStop)

	useEffect(() => {
		unsubscribe.current = firebase.auth().onAuthStateChanged(user => {
			setUser(user)
			setPending(false)

			if (reallyAutoStop.current)
				unsubscribe.current()
		})
		return unsubscribe.current
	}, [])

	return { user, login, logout, pending, unsubscribe: unsubscribe.current }
}
