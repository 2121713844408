import React from 'react'
import styled from 'styled-components'

import { ReactComponent as LotitchSVG } from 'assets/imgs/Lotitch.svg'
import { ReactComponent as QuoteSVG } from 'assets/imgs/Quote.svg'
import { ReactComponent as CornerSVG } from 'assets/imgs/Corner.svg'

const Lotitch = styled(LotitchSVG)`
	position: absolute;
	left: 5vw;
	top: 10vh;
`

const Quote = styled(QuoteSVG)`
	position: absolute;
	left: 5vw;
	top: 20vh;
`

const Corner = styled(CornerSVG)`
	position: absolute;
	right: 5vw;
	bottom: 10vh;
`

export const Brands = () => {
	return <>
		<Lotitch />
		<Quote />
		<Corner />
	</>
}
