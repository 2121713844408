import styled from "styled-components";

export const ButtonsContainer = styled.div`
	--radius: 3px;

	> button:first-child {
		border-top-left-radius: var(--radius);
		border-bottom-left-radius: var(--radius);
	}

	> button:last-child {
		border-top-right-radius: var(--radius);
		border-bottom-right-radius: var(--radius);
	}
`